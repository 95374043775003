import React from "react";
import classNames from "classnames";

const TextArea = ({
  name,
  value,
  onChange,
  type,
  required,
  labelName,
  className,
}) => {
  return (
    <div className={classNames("textarea", className)}>
      <textarea
        id={`${labelName}-text-area`}
        name={name}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type={type}
        required={required}
        className="textarea__element"
        wrap="hard"
      />
      <span className="textarea__highlight" />
      <span className="textarea__bar" />
      <label className="textarea__label" for={`${labelName}-text-area`}>
        {labelName}
      </label>
    </div>
  );
};

export default TextArea;
