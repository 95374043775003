import React, { useState, useEffect } from "react";
import Input from "../atoms/Input";
import TextArea from "../atoms/TextArea";
import Button from "../atoms/Button";
import Select from './Select';
import countryData from "../../../static/new-world-110m.json";

const countryOptions = countryData.objects.gadm36_0.geometries.map(
  (c) => {
    return {
      value: c.properties.GID_0,
      label: c.properties.NAME_0,
    };
  }
);

const getCountryOptions = () => {
  return countryOptions.sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });
}

const ContactForm = ({ receiverEmail, headerTitle }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [falseEmail, setFalseEmail] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  return (
    <div className="contactForm__background">
      <form
        className="contactForm__form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSdjMmQ2PihEUm1HE8PST7wuFuSo_0OX9Hyetj_60DOkkxB8cQ/formResponse"
        method="post"
      >
        <div className="contactForm__hidden">
          <Input required={false} type="text" value={falseEmail} name="email" />
        </div>
        <div className="contactForm">
          <h2 className="contactForm__header">{headerTitle.toUpperCase()}</h2>
          <div className="contactForm__input">
            <Input
              name="entry.824772849"
              labelName="Name"
              required={true}
              type="text"
              onChange={(event) => setName(event.target.value)}
              value={name}
            />
          </div>
          <div className="contactForm__input">
            <Input
              name="entry.2034351955"
              labelName="Email"
              required={true}
              type="email"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
          </div>
          <div className="contactForm__input">
            <Input
              name="entry.2006685871"
              labelName="Country"
              required={true}
              type="text"
              onChange={(event) => setSelectedCountry(event.target.value)}
              value={selectedCountry}
            />
          </div>
          <div className="contactForm__input">
            <TextArea
              name="entry.426782815"
              cols="60"
              rows="10"
              required={true}
              labelName="Message"
              value={message}
              onChange={setMessage}
            />
          </div>
          <Button
            type="submit"
            disabled={
              !name || !email || !message || falseEmail || !selectedCountry
            }
            text="SUBMIT"
          />
        </div>
        <div style={{ visibility: "hidden" }}>
          <Input
            required={true}
            type="text"
            value={receiverEmail}
            name="entry.1775038823"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
