import React from "react";
import LogoWithName from "../../../assets/LogoWithName.jpg";
import ContactForm from "../../atoms/ContactForm";

const Contact = () => {
  return (
    <div className="contact">
      <img src={LogoWithName} height={150} className="landingLogo" />
      <div className="contactFormDiv">
        <ContactForm
          receiverEmail="sharebiblesapp@gmail.com"
          headerTitle="Contact Us"
        />
      </div>
    </div>
  );
};

export default Contact;
